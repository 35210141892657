@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.editor-menu-sidecar {
    max-width: 200px;
    max-height: 100%;
    background: white;
    border-radius: 4px;
    box-shadow: $sharp-shadow-shallow;
    position: absolute;
    z-index: 11;
    top: 0;
    right: 100%;
    overflow: auto;

    hr {
        margin: 0;
    }

    .close-button {
        right: 3px;
        top: 0;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: unset;
        position: fixed;
        bottom: 0;
        right: 0;
        top: unset;
        z-index: 1002;
    }
}
