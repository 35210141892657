@import '../../variables.scss';

.dropdown-menu-container {
    position: relative;
    display: inline-block;

    .dropdown-button {
        min-width: 42px;
    }

    &.menu-open {
        .dropdown-button {
            background: rgba(0, 0, 0, 0.08);
        }
    }

    .dropdown-panel {
        background: white;
        box-shadow: $sharp-shadow-shallow;
        border-radius: 4px;
        z-index: 1;
        position: absolute;
        right: 0;
        top: 36px;
        padding: 0.25rem 0;

        &.menu-position-top {
            top: unset;
            bottom: 36px;
        }
    }

    a[href].dropdown-menu-item,
    a:not([href]):not([tabindex]).dropdown-menu-item {
        padding: 0.1rem 1rem;
        white-space: nowrap;
        cursor: pointer;
        text-decoration: none;
        color: #212529;
        display: block;

        &:hover {
            background: #efefef;
            color: #212529;
            display: block;
        }
    }
}
