@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '@labradorsports/components/variables.scss';

$frame-buttons-width: 180px;

.formation-selector {
    height: 100px;
    background: $primary-blue;
    box-shadow: $sharp-shadow-shallow;
    position: relative;
    flex: 0 0 auto;
    color: white;

    .formation-list-container {
        height: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        margin-left: $frame-buttons-width;
    }

    .formation-list {
        height: 100%;
        display: flex;
        padding: 0 20px;
        float: left;
        align-items: center;

        & > * {
            margin: 5px;
            flex-shrink: 0;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 5px;
        }
    }

    .formation {
        text-align: center;
        padding: 5px;
        line-height: 1;

        &:hover {
            background: transparentize($color: #ffffff, $amount: 0.9);
        }
    }

    .formation-preview {
        width: 110px;
        height: 75px;
        cursor: pointer;
        text-align: center;
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        display: inline-block;
    }

    .title-section {
        position: absolute;
        height: 100%;
        width: $frame-buttons-width;
        background: $dark-blue;
        box-shadow: $sharp-shadow-shallow;
        z-index: 2;
        color: white;
        padding: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: start;
    }

    .hide-search-button {
        font-size: 1.7rem;
    }

    .search-container {
        position: absolute;
        left: 0;
        box-shadow: $sharp-shadow-shallow;
        background: white;
        bottom: 100%;
        display: flex;
    }

    .formation-name {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 110px;
        white-space: nowrap;
    }

    .formation-details-button {
        width: 36px;
        height: 32px;
        position: absolute;
        top: 0;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        border-radius: 6px;
    }

    @include media-breakpoint-down(sm) {
        height: 130px;
        display: flex;
        flex-direction: column;
        transition: height 0.15s linear;

        .formation-list-container {
            display: flex;
            margin-left: 0;
            height: auto;
        }

        .title-section {
            position: static;
            height: auto;
            width: 100%;
        }

        .search-container {
            z-index: 10;
            right: 0;
        }

        .frame-buttons {
            width: 110px;
            line-height: 50px;
        }

        .formation-preview {
            width: 80px;
            height: 55px;
        }
    }
}
