@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.community-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .community-tabs {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: wrap;

        .community-search {
            margin-right: 5px;
        }

        .MuiTabs-root {
            flex-grow: 1;
        }
    }

    .community-content {
        flex-basis: 100%;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .community-banner {
        margin-bottom: 2rem;

        img {
            width: 150px;
            height: 150px;
            margin-right: 1rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .community-search {
            width: 100%;
            margin: 0 5px;

            .MuiFormControl-root {
                width: 100%;
            }
        }
    }
}
