@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.player-pill {
    background: #f7f7f7;
    border-radius: 5px;
    cursor: move;
    box-shadow: $soft-shadow-shallow;
    padding: 5px;
    border: 1px solid #ddd;
    margin: 5px 0;
    position: relative;

    &.with-handle {
        cursor: default;
    }

    .jersey {
        width: 50px;
        display: inline-block;
    }

    .position {
        width: 100px;
        display: inline-block;
    }

    .handle {
        position: absolute;
        cursor: move;
        width: 24px;
        height: 24px;
        right: 5px;
        top: 5px;

        &:after,
        &:before {
            position: absolute;
            content: ' ';
            width: 24px;
            height: 16px;
            border: 1px solid #ccc;
            border-right: none;
            border-left: none;
            top: 4px;
            left: 0;
        }

        &:after {
            top: 12px;
            border-bottom: none;
        }
    }
}
