.schedule-feed {
    .feed-date-header {
        text-align: center;
        font-weight: normal;
        margin-top: 1rem;
    }

    .schedule-event-card {
        border-radius: 0;
    }
}
