@import '@labradorsports/components/variables.scss';

.offline-banner {
    position: fixed;
    left: 0;
    top: 0;
    font-size: 0.8rem;
    color: white;
    width: 100%;
    text-align: center;
    z-index: 1002;

    .offline {
        background: $red;
    }

    .back-online {
        background: $green;
    }
}
