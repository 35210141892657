@import '@labradorsports/components/variables.scss';

.play-metadata {
    position: absolute;
    top: 58px;
    left: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: $sharp-shadow-shallow;
    z-index: 11;
    padding: 0.5rem;
    width: 300px;
    line-height: normal;
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .metadata-details {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .tags .row {
        margin-bottom: 0.5rem;
    }

    .tag-section-header {
        font-size: 80%;
        padding: 0 0 0.25rem 1rem;
        text-transform: uppercase;
    }

    .tag-label {
        margin: 0;
    }

    .tag-checkbox {
        padding: 5px;
    }
}
