@import '@labradorsports/components/variables.scss';

.scouting-report-brief {
    padding-bottom: 46px;

    .view-full-report-button {
        position: absolute;
        bottom: calc(1.5rem + $buttons-bar-height);
        text-align: center;
        width: 100%;

        button {
            box-shadow: $sharp-shadow-shallow;
        }
    }
}
