@import '@labradorsports/components/variables.scss';

.view-document-modal {
    h1 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .document-preview {
        width: 100%;
        height: 400px;
        position: relative;

        iframe {
            border: none;
            width: 100%;
            height: 100%;
        }
    }

    .acknowledgement-box {
        position: absolute;
        bottom: 1rem;
        z-index: 1;
        width: 100%;

        .card {
            box-shadow: $sharp-shadow-shallow;
            border-radius: 4px;
            padding: 1rem;
        }
    }
}
