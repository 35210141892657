@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.onboarding-steps {
    max-width: $panel-content-width;
    margin: auto;
    position: relative;
    z-index: 1;

    &.collapsed {
        max-width: 600px;
    }

    .step-carousel {
        display: flex;
        width: 100%;
    }

    .onboarding-step {
        margin: 5px;
        flex: 1 1 33%;
        display: none;

        &.active-0,
        &.active-1,
        &.active-2 {
            display: block;
        }
    }

    .carousel-dots {
        text-align: center;
    }

    .carousel-dot {
        display: inline-block;
        background: #ddd;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        margin: 5px 3px;
        cursor: pointer;

        &.active-0,
        &.active-1,
        &.active-2 {
            background: $dark-blue;
        }
    }

    .arrow {
        cursor: pointer;
    }

    .arrow.prev.active-0 {
        opacity: 0.5;
        pointer-events: none;
    }

    .arrow.next {
        &.active-0,
        &.active-1,
        &.active-2 {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .collapse-button {
        position: absolute;
        bottom: 0.25rem;
        right: 0.25rem;
    }

    @include media-breakpoint-down(lg) {
        .onboarding-step.active-2 {
            display: none;
        }

        .carousel-dot.active-2 {
            background: #ddd;
        }

        .arrow.next.active-2 {
            opacity: 1;
            pointer-events: unset;
        }
    }

    @include media-breakpoint-down(md) {
        .onboarding-step.active-1 {
            display: none;
        }

        .carousel-dot.active-1 {
            background: #ddd;
        }

        .arrow.next.active-1 {
            opacity: 1;
            pointer-events: unset;
        }
    }
}
