.global-loading-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1000;

    .loading-spinner {
        position: absolute;
        padding: 0;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: -16px;
    }
}
