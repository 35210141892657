@import '../../variables.scss';

.delete-player {
    background: white;
    border: 1px solid #aaa;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    opacity: 0.5;
    transition: box-shadow 0.1s ease-in;

    img {
        width: 90%;
        margin-left: 5%;
    }

    &.active {
        opacity: 1;
    }

    &.hovered {
        opacity: 1;
        box-shadow: $sharp-shadow-shallow;
    }
}
