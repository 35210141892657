@import '@labradorsports/components/variables.scss';

.button-menu {
    background: white;
    border-radius: 4px;
    box-shadow: $sharp-shadow-shallow;
    position: absolute;

    img {
        width: 28px;
        height: 28px;
        margin-right: 4px;
    }

    .MuiButton-root {
        display: flex;
        justify-content: start;
    }
}
