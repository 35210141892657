@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.toolbox {
    z-index: 6;
    width: 180px;
    border-left: 1px solid #ddd;
    box-shadow: $soft-shadow-shallow;
    align-items: center;
    flex-shrink: 0;
    background: white;
    display: flex;
    flex-direction: column;
    position: relative;

    @include media-breakpoint-down(sm) {
        width: 100%;
        height: 70px;
        flex-direction: row;
        justify-content: start;
        padding: 0;
        align-items: center;

        .tool {
            margin: 0;
        }
    }
}
