.play-debug-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .grid-display {
        width: 100%;
        height: 100%;
    }
}
