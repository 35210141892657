@import '@labradorsports/components/variables.scss';

.post-comment {
    position: relative;
    padding: 0.5rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: $soft-shadow-shallow;

    & + .post-comment {
        margin-top: 0.5rem;
    }

    .delete-button {
        background: white;
        cursor: pointer;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        text-align: center;
        line-height: 14px;
        position: absolute;
        right: -7px;
        top: -7px;
        box-shadow: $sharp-shadow-shallow;
    }

    .profile-picture {
        width: 36px;
        height: 36px;
    }
}
