@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '@labradorsports/components/variables.scss';

.frame {
    width: 90px;
    height: 60px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    border: 1px solid transparent;

    &.current {
        box-shadow: $sharp-shadow-shallow;
    }

    .remove-frame,
    .comment {
        position: absolute;
        top: -15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
        z-index: 1;
        background: white;

        img {
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            margin: -1px;
        }
    }

    .comment {
        left: -10px;
    }

    .remove-frame {
        right: -10px;
    }

    @include media-breakpoint-down(sm) {
        width: 70px;
        height: 45px;

        .comment {
            display: none;
            top: 5px;
            left: -15px;

            &.added {
                display: block;
            }
        }

        &.current {
            .comment:not(.hidden) {
                display: block;
            }
        }

        .remove-frame {
            top: 5px;
            right: -15px;
        }
    }
}
