@import '@labradorsports/components/variables.scss';

.avatar {
    width: 48px;
    height: 48px;
    position: relative;
    cursor: pointer;

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    .notification-count {
        position: absolute;
        bottom: -2px;
        right: -2px;
        background: $orange;
        border-radius: 50%;
        color: white;
        font-size: 80%;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        box-shadow: $sharp-shadow-shallow;
    }
}
