@import '@labradorsports/components/variables.scss';

.move-to-folder-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 12;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.3);
    overflow: auto;

    .move-to-folder {
        background: white;
        box-shadow: $sharp-shadow-shallow;
    }
}
