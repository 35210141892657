@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

$header-height: 105px;
$short-header-height: 70px;
$header-menu-height: $header-height - $short-header-height;
$buttons-bar-height: 70px;

$jumbo-content-top-margin: 100px;

$footer-height: 120px;

$frame-timeline-height: 100px;
$frame-timeline-height-mobile: 60px;

$frame-comment-display-height: 100px;
$frame-comment-display-height-mobile: 60px;

$panel-content-width: 1400px;

$app-chrome-header-height: 70px;
$app-chrome-nav-height: calc(max(58px + env(safe-area-inset-bottom), 66px));
$app-bottom: #{'calc(max(env(safe-area-inset-bottom) - 8px, 0px))'};

$primary-blue: #003e5e;
$dark-blue: #000229;

$orange: #ff6700;

$red: #dc3545;

$green: #72bf00;

$yellow: #f0bd27;

$background: #fcfcfc;

// Bootstrap overrides
$primary: $primary-blue;
$warning: $orange;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

$left-panel-min-width: 350px;

$sharp-shadow-shallow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);
$soft-shadow-broad: 1px 1px 15px -1px rgba(0, 0, 0, 0.15);
$soft-shadow-shallow: 1px 1px 6px -1px rgba(0, 0, 0, 0.15);

@mixin left-panel-width {
    width: 25%;
    min-width: $left-panel-min-width;
    max-width: 500px;

    @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: inherit;
        min-width: inherit;
    }
}
