@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.frame-comment {
    z-index: 6;
    background: white;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 5px 5px 15px;
    box-shadow: $sharp-shadow-shallow;

    .close {
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 3px;
    }

    &.playing {
        textarea {
            color: #212529;
        }
    }

    @include media-breakpoint-down(sm) {
        position: fixed;
        z-index: 15;
    }
}

.app-container {
    .frame-comment {
        @include media-breakpoint-down(sm) {
            bottom: $app-chrome-nav-height;
        }
    }
}
