@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.dashboard-page {
    .dashboard-content {
        position: relative;
    }

    @include media-breakpoint-down(sm) {
        .page-content-panel {
            padding: 0;

            .row {
                margin-left: 0;
                margin-right: 0;

                .col-md-8 {
                    padding: 0;
                }
            }

            .card {
                border-radius: 0;
            }

            h5 {
                padding: 0 0.5rem;
            }

            .post-feed-item {
                .card {
                    border-radius: 0.5rem;
                }
            }
        }
    }
}
