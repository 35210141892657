@import '../../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.peek-panel {
    background: white;
    box-shadow: $soft-shadow-shallow;
    position: absolute;
    top: calc(100% - $buttons-bar-height);
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 12;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    transition: top 0.6s cubic-bezier(0.52, 1.46, 0.46, 0.88);

    &.open {
        transition: top 0.4s ease-in;
        top: 5px;
    }

    .close-button {
        z-index: 5;
    }

    .opener {
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }
}
