@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.playback-speed {
    background: white;
    border-radius: 4px;
    box-shadow: $sharp-shadow-shallow;
    position: absolute;
    bottom: 0;
    left: 10px;
    z-index: 10;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .speed-button {
        width: 45px;
        height: 45px;
        min-width: auto;

        img {
            width: 40px;
            height: 40px;
            margin-right: 0;
        }

        &.selected {
            box-shadow: $sharp-shadow-shallow;
            background: #eee;
        }
    }

    .close-container {
        height: 50px;
        border-left: 1px solid #ddd;
        display: flex;
    }

    @include media-breakpoint-down(sm) {
        left: 0;
        width: 100%;
    }
}
