@import '@labradorsports/components/variables.scss';

.event-pill {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0.5rem;
    padding-left: 1rem;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 0.15s ease-in;
    margin-bottom: 0.25rem;
    flex-wrap: wrap;
    display: inline-block;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-color: var(--event-color);
        width: 8px;
    }

    &:hover {
        box-shadow: $sharp-shadow-shallow;
    }

    .event-pill-type {
        margin-right: 0.5rem;
        text-transform: uppercase;
    }
}
