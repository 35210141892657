@use 'sass:math';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '@labradorsports/components/variables.scss';

$frame-buttons-width: 180px;
$slider-handle-size: 12px;

.football-timeline {
    height: 100px;
    background: #f0f0f0;
    box-shadow: $sharp-shadow-shallow;
    position: relative;
    font-size: 0.8rem;
    flex: 0 0 auto;
    user-select: none;
    z-index: 7;

    .frame-list-container {
        height: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        margin-left: $frame-buttons-width;
        padding: 1rem;
        display: flex;
        align-items: center;
    }

    .timeline-slider {
        width: 100%;
        height: 60px;
        position: relative;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            width: calc(100% + 1px);
            height: 40px;
            top: 10px;
            border-left: 1px solid black;
            border-right: 1px solid black;
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            top: 29px;
            border-bottom: 2px solid #777;
        }
    }

    .played-shading {
        height: 4px;
        position: absolute;
        left: 0;
        top: 28px;
        background: $orange;
        z-index: 1;
    }

    .slider-handle {
        border-radius: 50%;
        width: $slider-handle-size;
        height: $slider-handle-size;
        background: $dark-blue;
        position: absolute;
        right: math.div($slider-handle-size, -2);
        top: math.div($slider-handle-size, -2) + 2px;
        transition: transform 0.1s linear;
        transform: scale(1);

        &.dragging {
            transform: scale(1.5);
        }
    }

    .timeline-marker {
        position: absolute;
        display: flex;
        align-items: center;
        transform: translateX(-50%);
        bottom: calc(100% - 10px);

        &:after {
            content: '';
            position: absolute;
            border-left: 1px solid black;
            height: 30px;
            top: calc(100% + 5px);
            left: 50%;
        }
    }

    .timeline-shading {
        position: absolute;
        height: 100%;
        background: rgba(125, 125, 125, 0.1);
        text-align: center;

        span {
            display: inline-block;
            transform: translateY(60px);
        }
    }

    .add-video-button {
        .add-video-icon {
            height: 100%;
        }

        .video-thumbnail {
            height: 100%;
            width: 100%;
        }
    }

    .title-screen-button {
        width: 90px;
        height: 60px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 45px;
            height: 45px;
        }
    }

    .frame-buttons {
        display: inline-block;
        position: absolute;
        height: 100%;
        width: $frame-buttons-width;
        line-height: 100px;
        background: white;
        box-shadow: $sharp-shadow-shallow;
        z-index: 2;

        .frame-button {
            height: 80px;
            min-width: 80px;
            padding: 0;
            flex-direction: column;

            .btn-label {
                display: block;
                margin-top: 10px;
                line-height: normal;
            }
        }

        .play-buttons {
            clear: both;
            text-align: center;

            .MuiButton-root {
                img {
                    width: 40px;
                    height: 40px;
                    margin: 0;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        height: 60px;

        .frame-list-container {
            margin-left: 110px;
        }

        .timeline-slider {
            height: 40px;

            &:before {
                height: 30px;
                top: 5px;
            }

            &:after {
                top: 20px;
            }

            .slider-handle {
                width: 15px;
                height: 15px;
                top: 12.5px;
                margin-left: -7.5px;
            }
        }

        .timeline-marker {
            transform: translateX(-50%);
            bottom: calc(100% - 5px);

            &:after {
                height: 20px;
            }
        }

        .timeline-shading {
            span {
                transform: translateY(30px);
            }
        }

        .add-video-button {
            width: 70px;
            height: 45px;
        }

        .frame-buttons {
            width: 110px;
            line-height: 50px;

            .frame-button {
                height: 50px;
                min-width: 50px;
                width: 50px;

                .btn-label {
                    display: none;
                }
            }

            .play-buttons {
                margin-top: 5px;
            }
        }
    }
}
