.loading-spinner {
    text-align: center;
    padding: 1rem;

    img {
        display: inline-block;
        width: 32px;
        height: 32px;
    }
}
