@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.community-play-card.card {
    display: inline-flex;
    padding: 0;
    min-width: 300px;
    cursor: pointer;
    transition: transform 0.1s linear;
    color: #333;
    text-decoration: none;

    &:hover {
        transform: translate(-1px, -1px);
    }

    .play-preview {
        width: 100%;
        text-align: center;
        box-shadow: $soft-shadow-shallow;
        height: 200px;
        border-radius: 8px 8px 0 0;
        overflow: hidden;

        img {
            height: 200px;
        }
    }

    .community-play-details {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .play-tags {
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
        }
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}
