@import '@labradorsports/components/variables.scss';

.event-details {
    padding-bottom: 2rem;

    .event-type {
        font-weight: normal;
        font-size: 1.5rem;
    }

    .event-field {
        display: inline-block;
        min-width: 200px;
        margin: 0 1rem 1rem 0;

        .address-display > * {
            display: block;
        }
    }

    .event-field-name {
        text-transform: uppercase;
        font-size: 0.8rem;
    }

    .event-date b {
        text-transform: uppercase;
    }

    .send-reminder-button {
        position: absolute;
        bottom: calc(1.5rem + $buttons-bar-height);
        left: 0;
        text-align: center;
        width: 100%;

        button {
            background: white;
            box-shadow: $sharp-shadow-shallow;
        }
    }
}
