@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '@labradorsports/components/variables.scss';

$player-profile-header-height: 100px;

.player-profile {
    display: flex;
    flex-direction: column;
    background-color: #fcfcfc;

    .player-header {
        background: $dark-blue;
        color: white;
        position: relative;
        height: $player-profile-header-height;
        padding: 1rem;

        .profile-picture {
            width: 72px;
            height: 72px;
            margin-right: 0.5rem;
        }

        .player-name {
            font-size: 1.75em;

            .jersey {
                margin-right: 7px;
            }
        }

        .rating {
            line-height: 2rem;
            font-size: 3rem;
        }

        .change-icon {
            display: inline-block;
            width: 25px;
            height: 25px;
        }
    }

    .player-header-info,
    .MuiTabs-flexContainer {
        margin: auto;
        max-width: $panel-content-width;
        width: 100%;
    }

    .player-header-info {
        display: flex;
        align-items: center;
    }

    .player-profile-content {
        flex-grow: 1;
    }
}
