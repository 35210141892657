@import '@labradorsports/components/variables.scss';

.depth-chart-container {
    box-shadow: $soft-shadow-broad;

    .container-header {
        color: white;
        background: $primary-blue;
        padding: 10px;
    }

    .player-list {
        min-height: 75px;
        background: #ddd;

        .player-section {
            padding: 0px 10px;
            overflow: auto;

            &.starting {
                background: white;
            }
        }
    }

    .player-placeholder {
        opacity: 0.5;
    }

    .starter-name {
        text-transform: uppercase;
        display: block;
        margin-top: 5px;
    }
}

.depth-chart-initialize-button {
    margin-bottom: 10px;
    justify-content: start;
}
