@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.movement-move-editor-menu {
    max-width: 180px;

    img {
        width: 28px;
        height: 28px;
        margin: 0;
    }

    .move-type-selector {
        display: flex;
        flex-wrap: wrap;
        margin: 3px;
        justify-content: center;
        gap: 3px;
    }

    .move-type-option {
        width: 50px;
        height: 50px;
        min-width: auto;
        flex-basis: 50px;
        flex-grow: 0;

        &.Mui-disabled {
            background: #e0e0e0;
        }

        img {
            width: 34px;
            height: 34px;
            margin: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        max-width: unset;
    }
}
