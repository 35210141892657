@import '@labradorsports/components/variables.scss';

.play-list-play {
    .play-name-container {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .play-name {
        font-weight: bold;
        white-space: nowrap;
    }

    &.viewed {
        .play-name {
            font-weight: normal;
        }
    }

    .viewer-count {
        button {
            padding: 0;
        }
    }

    .unsaved {
        color: $red;
        line-height: 42px;
    }

    .play-tag-collapse {
        display: flex;
        flex-direction: row;

        .play-tag-display {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-grow: 1;
            min-width: 0px;
        }

        .button-link {
            white-space: nowrap;
            font-size: 0.8em;
        }

        &.expanded {
            .play-tag-display {
                display: inline;
                white-space: unset;
            }
        }
    }

    &.blurred {
        cursor: default;

        &:hover {
            background: none;
        }

        .play-name {
            filter: blur(3px);
        }
    }
}
