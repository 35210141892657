.attendance-badge {
    border-radius: 1rem;
    text-align: center;
    display: inline-block;
    line-height: normal;
    padding: 0.25rem 0.5rem;

    &.editable {
        cursor: pointer;
    }

    &.going {
        background-color: $green;
    }

    &.maybe {
        background-color: $yellow;
    }

    &.notgoing {
        background-color: lighten($red, 10%);
    }
}
