@import '@labradorsports/components/variables.scss';

.logged-in-menu {
    position: absolute;
    background: white;
    border-radius: 4px;
    box-shadow: $sharp-shadow-shallow;
    top: $short-header-height;
    right: 7px;
    transform: translateY(0);
    opacity: 1;
    z-index: 10;

    &.header-menu-enter {
        opacity: 0.01;
        transform: translateY(-8px);
    }

    &.header-menu-enter.header-menu-enter-active {
        transition: transform 0.2s ease-in, opacity 0.2s ease-in;
        transform: translateY(0);
        opacity: 1;
    }

    &.header-menu-exit {
        opacity: 1;
        transform: translateY(0);
    }

    &.header-menu-exit.header-menu-exit-active {
        transition: transform 0.2s ease-in, opacity 0.2s ease-in;
        transform: translateY(-8px);
        opacity: 0.01;
    }

    & > * {
        position: relative;
        z-index: 1;
    }

    &:before {
        content: '';
        background: white;
        transform: rotate(-45deg);
        transform-origin: 0 0;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
        top: 0;
        border-top: 1px solid #ddd;
        border-right: 1px solid #ddd;
        z-index: 0;
    }

    .profile-setup-steps {
        border-top: 1px solid #ddd;
        text-align: center;
    }

    .step-carousel {
        display: flex;

        & > * {
            flex: 0 0 33%;
        }
    }

    .logout-button {
        color: $red;
        width: 100%;
    }

    .menu-item {
        color: inherit;
        display: block;
        text-transform: uppercase;
        font-weight: 500;
        padding: 7px 15px;
        font-size: 0.8em;
        text-decoration: none;

        &:hover {
            background: darken(white, 10%);
        }
    }

    .social-icon {
        margin: 3px;
    }

    .new-badge {
        color: white;
        background: $orange;
        padding: 3px;
        line-height: 1rem;
        height: calc(1rem + 6px);
        margin-left: 0.5rem;
        border-radius: 3px;
    }
}
