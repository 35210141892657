@import '../../variables.scss';
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.pull-to-refresh {
    .ptr-panel-container {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
    }

    .ptr-panel-content {
        background: $background;
        flex: 1 1 100%;
    }

    .ptr__children {
        height: 100%;
    }

    .ptr__pull-down {
        z-index: 0;
    }

    .ptr__loader {
        z-index: 3;
        position: relative;
    }

    .ptr__pull-down--pull-more {
        height: 320px;
        display: block;

        .pull-to-update {
            position: relative;
            display: block;
            padding-top: 5px;
            width: 200px;
            margin: auto;
            font-size: 0.8rem;
            text-align: center;
            border-bottom: 1px solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(
                to right,
                rgba(221, 221, 221, 0),
                rgba($orange, 0.5),
                rgba(221, 221, 221, 0)
            );
            color: $orange;
            transform: translateY(-20px);
            transition: transform 0.3s linear;
            z-index: 3;
        }

        .panel-header {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .header-text {
                display: none;
            }
        }
    }

    &.ptr--dragging,
    &.ptr--pull-down-treshold-breached {
        .ptr__pull-down {
            opacity: 1 !important;
            visibility: visible !important;

            .panel-header {
                min-height: 320px;

                .logo-container {
                    overflow: visible;
                }
            }
        }

        .ptr__pull-down--pull-more {
            .pull-to-update {
                transform: translateY(0);
            }
        }

        .ptr__children {
            .panel-header .logo-container {
                opacity: 0;
            }
        }
    }

    &.ptr--pull-down-treshold-breached {
        .ptr__pull-down--pull-more {
            display: block;

            .pull-to-update {
                display: none;
            }
        }
    }

    .panel-header {
        &.has-logo {
            .logo-container:after {
                top: -200%;
            }
        }
    }
}
