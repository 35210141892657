.print-play-page {
    display: none;

    @media print {
        display: block;
        height: auto;

        .page-header {
            position: fixed;
            top: 0;
        }

        .page-footer {
            position: fixed;
            bottom: 0;
            text-align: right;
        }

        .frame-container {
            display: block;
            position: relative;
            margin: auto;
        }

        .field-container {
            position: relative;
            overflow: hidden;
        }

        .comments-container {
            overflow: hidden;
            flex-basis: 50%;
        }

        .frame-counter {
            position: absolute;
            top: 0;
            left: 0;
        }

        &.layout-3 {
            .frame-container {
                margin-top: 1cm;
                display: flex;
            }

            .print-spacing-container {
                &:nth-child(3n) {
                    break-after: page;
                }

                &:nth-child(3n + 1) {
                    &:before {
                        content: '';
                        display: block;
                        height: 1cm;
                        width: 100%;
                        background: transparent;
                    }
                }
            }
        }

        &.layout-1 {
            .print-spacing-container {
                break-after: page;
                margin-top: 1cm;
            }
        }
    }
}
